<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12" class="form-for-textarea">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <b-row>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Purchase Order Memo No" vid="po_memo_no" rules='required'>
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="po_memo_no"
                                    slot-scope="{ valid, errors }"
                                    style="font-size:13px"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.po_order_memo_no')}}<span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="po_memo_no"
                                        v-model="lcCreate.po_memo_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="lcCreate.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Purchase Date" vid="purchase_date" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="purchase_date"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('procurement.purchase_date')}} <span class="text-danger">*</span>
                                </template>
                                <flat-pickr class="form-control"
                                v-model="lcCreate.purchase_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></flat-pickr>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Manufacture"  vid="manufacturer" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="manufacturer"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.manufacture')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="manufacturer"
                                        v-model="lcCreate.manufacturer"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                             <ValidationProvider name="Delivery"  vid="delivery" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="delivery"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.delivery')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="delivery"
                                        v-model="lcCreate.delivery"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                              <ValidationProvider name="Shipment Date" vid="shipment_date" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="shipment_date"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('procurement.shipment_date')}} <span class="text-danger">*</span>
                                </template>
                                <flat-pickr class="form-control"
                                v-model="lcCreate.shipment_date"
                                placeholder="Select Date"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></flat-pickr>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                             <ValidationProvider name="Loading Port" vid="port_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="port_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('procurement.loading_port')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="lcCreate.port_id"
                                    :options="portList"
                                    id="port_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                       <b-col lg="6" sm="12">
                             <ValidationProvider name="Country Name" vid="country_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="country_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('procurement.country_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="lcCreate.country_id"
                                    :options="importCountryList"
                                    id="country_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Destination (En)"  vid="destination" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="destination"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.destination_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="destination"
                                        v-model="lcCreate.destination"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                           <ValidationProvider name="Destination (Bn)"  vid="destination_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="destination_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.destination_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="destination"
                                        v-model="lcCreate.destination_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                           <ValidationProvider name="Memo No"  vid="memo_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="memo_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.memo_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="memo_no"
                                        v-model="lcCreate.memo_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                           <ValidationProvider name="LC Type"  vid="lc_type" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="lc_type"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.lc_type')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="lc_type"
                                        v-model="lcCreate.lc_type"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                           <ValidationProvider name="LC No"  vid="lc_no" rules="required|integer">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="lc_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.lc_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="lc_no"
                                        v-model="lcCreate.lc_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                           <ValidationProvider name="LC Date" vid="lc_date" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="lc_date"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('procurement.lc_date')}} <span class="text-danger">*</span>
                                </template>
                                <flat-pickr class="form-control"
                                v-model="lcCreate.lc_date"
                                placeholder="Select Date"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></flat-pickr>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                             <ValidationProvider name="Fertilizer Name" vid="fertilizer_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="fertilizer_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('procurement.fertilizer_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="lcCreate.fertilizer_id"
                                    :options="fertilizerNameList"
                                    id="fertilizer_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                         <b-col lg="6" sm="12">
                           <ValidationProvider name="Fertilizer Quantity"  vid="quantity" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="quantity"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.fertilizer_quantity')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="lc_no"
                                        v-model="lcCreate.quantity"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                           <ValidationProvider name="Lot no"  vid="lot_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="lot_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.lot_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="lot_no"
                                        v-model="lcCreate.lot_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Shipment (En)"  vid="ship_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="ship_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.shipname_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="ship_name"
                                        v-model="lcCreate.ship_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Shipment (Bn)"  vid="ship_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="ship_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.shipname_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="ship_name_bn"
                                        v-model="lcCreate.ship_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                           <ValidationProvider name="LC Price"  vid="lc_price" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="lc_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.lc_price')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type='number'
                                        id="lc_price"
                                        v-model="lcCreate.lc_price"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                           <ValidationProvider name="LC Opening Charge"  vid="lc_opening_charge" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="lc_opening_charge"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.lc_opening_charge')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                         type="number"
                                        id="lc_opening_charge"
                                        v-model="lcCreate.lc_opening_charge"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                           <ValidationProvider name="Fertilizer Receive Quantity"  vid="receive_quantity" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="receive_quantity"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.receive_quantity')}}
                                    </template>
                                    <b-form-input
                                        type="number"
                                        id="receive_quantity"
                                        v-model="lcCreate.receive_quantity"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                           <ValidationProvider name="Cnf Price"  vid="cnf_price" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="cnf_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('procurement.cnf_price')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        id="cnf_price"
                                        v-model="lcCreate.cnf_price"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { lcCreateStore, LcCreateUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getLcCreateData()
      this.lcCreate = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      lcCreate: {
        id: '',
        po_memo_no: '',
        purchase_date: '',
        manufacturer: '',
        delivery: '',
        shipment_date: '',
        port_id: 0,
        country_id: 0,
        destination: '',
        destination_bn: '',
        memo_no: '',
        lc_type: '',
        lc_no: '',
        lc_date: '',
        fertilizer_id: 0,
        fiscal_year_id: 0,
        quantity: '',
        lot_no: '',
        ship_name: '',
        ship_name_bn: '',
        lc_price: '',
        lc_opening_charge: '',
        receive_quantity: '',
        cnf_price: ''
      }
    }
  },
  computed: {
    fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    portList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    importCountryList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
    }
  },
  methods: {
    getLcCreateData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.lcCreate.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${LcCreateUpdate}/${this.id}`, this.lcCreate)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, lcCreateStore, this.lcCreate)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
