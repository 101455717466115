<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12" class="form-for-textarea">
                <b-overlay :show="loading">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" enctype="multipart/form-data">
                      <ValidationProvider name="Loading Date" vid="loading_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="loading_date"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('procurement.loading_date')}} <span class="text-danger">*</span>
                          </template>
                          <flat-pickr class="form-control"
                                        v-model="lcCreate.loading_date"
                                        :placeholder="$t('globalTrans.select_date')"
                                        id="datepicker"
                                        :state="errors[0] ? false : (valid ? true : null)"
                          ></flat-pickr>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Estimated Arival Date" vid="estimated_arival_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="estimated_arival_date"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('procurement.estimated_arival_date') }} <span class="text-danger">*</span>
                          </template>
                          <flat-pickr class="form-control"
                                        v-model="lcCreate.estimated_arival_date"
                                        :placeholder="$t('globalTrans.select_date')"
                                        id="datepicker"
                                        :state="errors[0] ? false : (valid ? true : null)"
                          ></flat-pickr>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { LcCreateUpdateDateInfo } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getLcCreateData()
      this.lcCreate = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      lcCreate: {
        id: '',
        loading_date: '',
        estimated_arival_date: ''
      },
      loading: false
    }
  },
  methods: {
    getLcCreateData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadinState = { loading: false, listReload: false }

      const result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${LcCreateUpdateDateInfo}/${this.id}`, this.lcCreate)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-6')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
