<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <table class="col-12 table">
                    <tr>
                        <th style="width: 20%">{{$t('procurement.po_order_memo_no')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.po_memo_no}}</td>
                        <th style="width: 20%">{{$t('warehouse_config.fiscal_year')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">
                          {{ getColumnName($store.state.commonObj.fiscalYearList,'value', lcCreate.fiscal_year_id) }}
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 20%">{{$t('procurement.purchase_date')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.purchase_date | dateFormat}}</td>
                        <th style="width: 20%">{{$t('procurement.manufacture')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.manufacturer}}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%">{{$t('procurement.delivery')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.delivery}}</td>
                        <th style="width: 20%">{{$t('procurement.shipment_date')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.shipment_date | dateFormat}}</td>
                    </tr>
                    <tr>
                        <th style="width: 20%">{{$t('procurement.destination_bn')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.destination_bn}}</td>
                        <th style="width: 20%">{{$t('procurement.memo_no')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.memo_no}}</td>
                    </tr>
                    <tr>
                        <th style="width: 20%">{{$t('procurement.lc_type')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.lc_type}}</td>
                        <th style="width: 20%">{{$t('procurement.lc_no')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.lc_no}}</td>
                    </tr>
                    <tr>
                        <th style="width: 20%">{{$t('procurement.lc_date')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.lc_date | dateFormat}}</td>
                        <th style="width: 20%">{{$t('procurement.fertilizer_quantity')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.quantity}}</td>
                    </tr>
                    <tr>
                        <th style="width: 20%">{{$t('procurement.lot_no')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.lot_no}}</td>
                        <th style="width: 20%">{{$t('procurement.shipname_en')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.ship_name}}</td>
                    </tr>
                    <tr>
                        <th style="width: 20%">{{$t('procurement.shipname_bn')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.ship_name_bn}}</td>
                        <th style="width: 20%">{{$t('procurement.lc_price')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.lc_price}}</td>
                    </tr>
                    <tr>
                        <th style="width: 20%">{{$t('procurement.lc_opening_charge')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.lc_opening_charge}}</td>
                        <th style="width: 20%">{{$t('procurement.receive_quantity')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{lcCreate.receive_quantity}}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%">{{$t('procurement.destination_en')}}</th>
                      <th style="width: 10%">:</th>
                      <td style="width: 20%">{{lcCreate.destination}}</td>
                      <th style="width: 20%">{{$t('procurement.cnf_price')}}</th>
                      <th style="width: 10%">:</th>
                      <td style="width: 20%">{{lcCreate.cnf_price}}</td>
                    </tr>
                </table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import seedFertilizerServiceBaseUrl from '@/config/api_config'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getLcCreateData()
      this.lcCreate = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      lcCreate: {
        id: '',
        po_memo_no: '',
        purchase_date: '',
        manufacturer: '',
        delivery: '',
        shipment_date: '',
        port_id: 0,
        country_id: 0,
        destination: '',
        destination_bn: '',
        memo_no: '',
        lc_type: '',
        lc_no: '',
        lc_date: '',
        fertilizer_id: '',
        quantity: '',
        lot_no: '',
        ship_name: '',
        ship_name_bn: '',
        lc_price: '',
        lc_opening_charge: '',
        receive_quantity: '',
        cnf_price: ''
      }
    }
  },
  methods: {
    getColumnName (list, field, groupId) {
      const obj = list.find(item => item[field] === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        } else {
          return obj.text_en
        }
      } else {
        return ''
      }
    },
    getLcCreateData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
